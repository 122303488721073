* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: white;
}

body {
  background: black;
  font-family: sans-serif!important;

}
a, span{
  text-transform: uppercase;
  font-family: sans-serif;
  text-decoration: none;
  color: white;
  font-size: 12px;
}
#links{
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: auto;
  z-index: 10;
  padding: 0 2.5% 2.5% 2.5%;
}

#links a{

  display: block;
  opacity: 1;
  
}

#links a#full-experience{
  display: block;
  margin: 0 auto 0 0;
}

#links a#dwnload-video{
  display: block;
  margin: 0 0 0 auto;
}

#instructions{
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  top: 2.5%;
  left: 0;
  width: 95vw;
  height: auto;
  z-index: 10;
  padding: 0 2.5% 2.5% 2.5%;
}

#instructions span{
  display: block;
  width: 100%;
  float: left;
}

canvas{
  opacity: 1;
}